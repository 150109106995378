import axios from 'axios';

export default {
  data: () => ({
    agents: [],
  }),

  mounted() {
    this.loadSuperAgents();
  },

  methods: {
    async loadSuperAgents() {
      // Submit the form

      try {
        const { data } = await axios.get(`/superagents`);

        this.agents = data;

        return true;
      } catch (e) {
        console.log('Error');

        return false;
      }
    },
  },
};
