<template>
    <div class="">
        <div class="flex justify-between items-center mb-6">
            <div>
                <h1 class="text-4xl">Super Agents</h1>
                <!-- <p class="text-sm text-gray-400">Welcome to your dashboard</p> -->
            </div>
            <div class="flex">
                <div>
                    <input
                        class="p-2 border w-80 rounded"
                        v-model="filter"
                        @keyup="search()"
                        type="search"
                        placeholder="Search"
                    />
                </div>
            </div>
            <router-link
                to="/superagents/add"
                class="
                    border
                    px-6
                    py-2
                    rounded-lg
                    text-sm
                    flex
                    justify-center
                    items-center
                    hover:bg-gray-100
                "
            >
                New Super Agent
                <i class="bx bxs-plus-circle pl-1"></i>
            </router-link>
        </div>

        <div class="flex flex-col">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div
                    class="
                        py-2
                        align-middle
                        inline-block
                        min-w-full
                        sm:px-6
                        lg:px-8
                    "
                >
                    <div
                        class="
                            shadow
                            overflow-hidden
                            border-b border-gray-200
                            sm:rounded-lg
                        "
                    >
                        <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        class="
                                            px-6
                                            py-3
                                            text-left text-xs
                                            font-medium
                                            text-gray-500
                                            uppercase
                                            tracking-wider
                                        "
                                    >
                                        Name
                                    </th>
                                    <th
                                        scope="col"
                                        class="
                                            px-6
                                            py-3
                                            text-left text-xs
                                            font-medium
                                            text-gray-500
                                            uppercase
                                            tracking-wider
                                        "
                                    >
                                        Available Balance
                                    </th>
                                    <th
                                        scope="col"
                                        class="
                                            px-6
                                            py-3
                                            text-left text-xs
                                            font-medium
                                            text-gray-500
                                            uppercase
                                            tracking-wider
                                        "
                                    >
                                        Status
                                    </th>

                                    <th scope="col" class="relative px-6 py-3">
                                        <span class="sr-only">Edit</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody
                                v-if="agents"
                                class="bg-white divide-y divide-gray-200"
                            >
                                <tr v-for="(agent, i) in agents.data" :key="i">
                                    <td class="px-6 py-4 whitespace-nowrap">
                                        <div class="flex items-center">
                                            <div
                                                class="flex-shrink-0 h-10 w-10"
                                            >
                                                <div
                                                    class="
                                                        h-10
                                                        w-10
                                                        rounded-full
                                                        bg-gray-200
                                                        flex
                                                        justify-center
                                                        items-center
                                                    "
                                                >
                                                    {{ agent.entity_name[0] }}
                                                </div>
                                            </div>
                                            <div class="ml-4">
                                                <div
                                                    class="
                                                        text-sm
                                                        font-medium
                                                        text-gray-900
                                                    "
                                                >
                                                    {{ agent.entity_name }}
                                                </div>
                                                <div
                                                    class="
                                                        text-sm text-gray-500
                                                    "
                                                >
                                                    {{ agent.contact_person }} /
                                                    {{
                                                        agent.contact_person_number
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap">
                                        <div class="text-sm text-gray-900">
                                            GMD
                                            {{
                                                formatPrice(agent.balance / 100)
                                            }}
                                        </div>
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap">
                                        <span
                                            v-if="agent.status"
                                            class="
                                                px-2
                                                inline-flex
                                                text-xs
                                                leading-5
                                                font-semibold
                                                rounded-full
                                                bg-green-100
                                                text-green-800
                                            "
                                        >
                                            Active
                                        </span>

                                        <span
                                            v-else
                                            class="
                                                px-2
                                                inline-flex
                                                text-xs
                                                leading-5
                                                font-semibold
                                                rounded-full
                                                bg-yellow-100
                                                text-yellow-800
                                            "
                                        >
                                            In-active
                                        </span>
                                    </td>

                                    <td
                                        class="
                                            px-6
                                            py-4
                                            whitespace-nowrap
                                            text-right text-sm
                                            font-medium
                                        "
                                    >
                                        <div class="flex justify-end">
                                            <button
                                                @click="onDropDown(i)"
                                                class="
                                                    relative
                                                    z-10
                                                    block
                                                    rounded-md
                                                    bg-white
                                                    text-xl
                                                    p-2
                                                    focus:outline-none
                                                "
                                            >
                                                <i
                                                    class="
                                                        bx
                                                        bx-dots-vertical-rounded
                                                    "
                                                ></i>
                                            </button>
                                        </div>
                                        <transition
                                            enter-active-class="transition ease-out duration-100"
                                            enter-from-class="transform opacity-0 scale-95"
                                            enter-to-class="transform opacity-100 scale-100"
                                            leave-active-class="transition ease-in duration-75"
                                            leave-from-class="transform opacity-100 scale-100"
                                            leave-to-class="transform opacity-0 scale-95"
                                        >
                                            <div
                                                v-show="
                                                    dropdownOpen.isOpen &&
                                                    dropdownOpen.index == i
                                                "
                                                @click="onDropDown(i)"
                                                class="
                                                    absolute
                                                    right-10
                                                    mt-2
                                                    py-2
                                                    w-48
                                                    bg-white
                                                    rounded-md
                                                    shadow-xl
                                                    z-20
                                                "
                                            >
                                                <router-link
                                                    :to="{
                                                        path: `/superagents/${agent.id}`,
                                                        //query: { ...agent },
                                                    }"
                                                    class="
                                                        block
                                                        px-4
                                                        py-2
                                                        text-sm
                                                        capitalize
                                                        text-gray-700
                                                        hover:bg-blue-500
                                                        hover:text-white
                                                    "
                                                    >View
                                                </router-link>
                                                <button
                                                    @click="toggleDeveloperMode"
                                                    class="
                                                        w-full
                                                        block
                                                        px-4
                                                        py-2
                                                        text-sm text-gray-700
                                                        hover:bg-blue-500
                                                        hover:text-white
                                                        text-right
                                                    "
                                                >
                                                    {{
                                                        agent.status
                                                            ? 'Deactivate'
                                                            : 'Activate'
                                                    }}
                                                </button>
                                            </div>
                                        </transition>
                                    </td>
                                </tr>

                                <!-- More people... -->
                            </tbody>
                        </table>

                        <div class="border-t" v-if="agents">
                            <pagination
                                :data="agents"
                                :midSize="2"
                                @clicked="loadData"
                            >
                            </pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Pagination from '../../components/Pagination';
import axios from 'axios';
import { mapGetters } from 'vuex';
import checkPermissionExist from '../../mixins/checkPermissionExist';
import SuperAgents from '../../mixins/SuperAgents';
import FormatPrice from '../../mixins/FormatPrice';

export default {
    middleware: 'auth',
    components: { Pagination },

    layout: 'default',

    mixins: [checkPermissionExist, SuperAgents, FormatPrice],
    computed: mapGetters({
        user: 'auth/user',
    }),

    data: () => ({
        filter: '',
        onFilter: false,

        filterAgents: '',
        filterDate: '',
        searchStaffs: [],

        selectedAgent: null,
        isConfirmModalVisible: false,
        dropdownOpen: {
            isOpen: false,
            index: null,
        },
        userHasConfirmed: false,
        modalType: '',
    }),

    created() {
        if (!this.checkPermissionExist(this.user.data, 'agents-read')) {
            // console.log("Hereeeeeeeeeeeeeeeeeee");
            this.$router.go(-1);
            return false;
        }
    },

    methods: {
        search() {
            this.searching = true;
        },

        onDropDown(i) {
            if (this.dropdownOpen.isOpen == false) {
                this.dropdownOpen.isOpen = true;
                this.dropdownOpen.index = i;

                return;
            }

            this.dropdownOpen.isOpen = false;
            this.dropdownOpen.index = null;
        },

        showConfirmModal(agent, type) {
            this.selectedAgent = agent;
            this.modalType = type;
            this.isConfirmModalVisible = true;
        },
        closeConfirmModal() {
            this.selectedAgent = null;
            this.isConfirmModalVisible = false;
        },

        confirmRequest(req) {
            console.log('User has confirmed', req);
            this.userHasConfirmed = req;

            if (this.userHasConfirmed == 'yes') {
                if (this.modalType === 'deactivate') {
                    this.updateStatus();
                }

                if (this.modalType === 'password') {
                    this.resetPassword();
                }
            }
        },
        async toggleDeveloperMode() {
            const { data } = await axios.get('/super_agent/toggle_active');
            console.log('Toggling developer mode: ', data);
        },
    },
};
</script>
